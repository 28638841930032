import React, { ReactElement } from 'react'
import { Link } from 'gatsby'

import { FeaturedArticleWrapper } from './styles'
import { AIArticleProps } from './types'
import htmlToExcerpt from '@/utils/html-to-excerpt'

export default function FeaturedArticle({
  aiArticle,
}: AIArticleProps): ReactElement {
  const article = aiArticle
  const link = `/search/top5/?s=${article.keyword}`
  const imageUrl = article.image_url

  return (
    <FeaturedArticleWrapper>
      <div className="wrapper">
        <div className="hp-block">
          <div className="hp-left">
            <Link to={link}>
              <div
                className="hp-image"
                style={{ backgroundImage: `url(${imageUrl})` }}
              />
            </Link>
          </div>
          <div className="hp-right">
            <div className="hp-info">
              <div className="hp-category">
                <Link to={link}>Featured</Link>
              </div>
              <div className="hp-title">
                <Link to={link}>{article.title}</Link>
              </div>
              <div className="hp-descr">
                <Link
                  to={link}
                  dangerouslySetInnerHTML={{
                    __html: htmlToExcerpt(article.content, 200),
                  }}
                />
              </div>
              <div className="hp-btn">
                <Link to={link}>Read More</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </FeaturedArticleWrapper>
  )
}
