import React, { ReactElement, useEffect, useState } from 'react'

import { PostArticlesWrapper } from './styles'
import { LatestAIArticleProps } from './types'
import AIArticleLatest from './AIArticleLatest'

const initial = 9

export default function PostArticles({
  aiArticles,
}: LatestAIArticleProps): ReactElement {
  const [limit, setLimit] = useState<number>(initial)

  useEffect(() => {
    setLimit(initial)
  }, [aiArticles])

  return (
    <PostArticlesWrapper>
      <div className="wrapper">
        <div className="post-title">Latest Articles</div>
        <div className="post-items">
          {aiArticles.slice(1, limit).map((article) => (
            <AIArticleLatest aiArticle={article} />
          ))}
        </div>
      </div>
    </PostArticlesWrapper>
  )
}
