import React, { ReactElement } from 'react'
import { Link } from 'gatsby'

import { Wrapper } from './styles'
import { AIArticleProps } from './types'
import UserImage from '@/assets/images/user.png'
import LogoFacebook from '@/assets/images/facebook-svgrepo-com.svg'
import LogoTwitter from '@/assets/images/twitter-svgrepo-com.svg'
import LogoPinterest from '@/assets/images/pinterest-svgrepo-com.svg'
import LogoEmail from '@/assets/images/email-svgrepo-com.svg'

/**
 * Article item component
 */
export default function AIArticleLatest({
  aiArticle,
}: AIArticleProps): ReactElement {
  const image = aiArticle.image_url

  return (
    <Wrapper>
      <div className="category-title">
        <span>{aiArticle.category}</span>
      </div>
      <div className="post-name">
        <Link to={`/search/top5/?s=${aiArticle.keyword}`}>
          {aiArticle.title}
        </Link>
      </div>
      <div className="post-author">
        <img className="avatar-img" src={UserImage} alt="User Avatar" />
        By <strong>{aiArticle.author}</strong>
        {/* &nbsp;&nbsp;|&nbsp;&nbsp;Published on {formattedDate} */}
        <div className="social-logo">
          <img src={LogoFacebook} alt="facebook logo" />
          <img src={LogoTwitter} alt="twitter logo" />
          <img src={LogoPinterest} alt="pinterest logo" />
          <img src={LogoEmail} alt="email logo" />
        </div>
      </div>
      <div className="post-descr">
        {!!image && (
          <img src={image} alt={aiArticle.title} />
          // <Link to={`/search/top5/?s=${aiArticle.keyword}`}>
          //   <div
          //     className="hp-image"
          //     style={{
          //       backgroundImage: `url(${image})`,
          //       width: '100%',
          //       height: '100%',
          //       backgroundSize: 'cover',
          //       backgroundPosition: '50%',
          //     }}
          //   />
          // </Link>
        )}
        <div
          dangerouslySetInnerHTML={{ __html: aiArticle.content }}
          className="content"
        />
      </div>
    </Wrapper>
  )
}
