import React, { ReactElement } from 'react'
import { useQueryParams, StringParam } from 'use-query-params'

import { Wrapper, AdItem, AdHead } from './styles'
import { YahooAdsProps } from './types'
import useClient from '@/utils/hooks/use-client'

export default function SearchYahooAds({
  yahooads,
  onClick,
}: YahooAdsProps): ReactElement {
  const [{ s }] = useQueryParams({
    s: StringParam,
  })
  const isClient = useClient()
  const handleClickParent = (id, taScore) => {
    onClick(id, taScore)
  }

  return (
    <Wrapper>
      <AdHead>Search for {capitalizeWords(s)}</AdHead>
      {isClient && !yahooads.length ? (
        <div>No ad(s) found</div>
      ) : (
        yahooads.map(
          ({
            id,
            url,
            title,
            description,
            clickUrl,
            impressionId,
            appNs,
            k,
            metadata,
            related,
            taScore,
          }) => (
            <AdItem
              key={`${id}`}
              data-yiid={impressionId}
              data-appns={appNs}
              data-k={k}
              data-admd={metadata}
            >
              <div
                className={`left-block ${
                  related.length ? `half-width` : `full-width`
                }`}
              >
                <a
                  onClick={() => handleClickParent(id, taScore)}
                  className="ad-yahoo"
                  title={title}
                  target="_blank"
                  rel="noreferrer"
                  href={clickUrl}
                >
                  <div
                    className="ad-title"
                    dangerouslySetInnerHTML={{ __html: title }}
                  />
                </a>

                <a
                  className="ad-description"
                  href={clickUrl}
                  target="_blank"
                  rel="noreferrer"
                  style={{ cursor: `pointer`, display: `block` }}
                  onClick={() => handleClickParent(id, taScore)}
                >
                  <div dangerouslySetInnerHTML={{ __html: description }} />
                </a>
                <a
                  className="ad-link"
                  href={clickUrl}
                  target="_blank"
                  rel="noreferrer"
                  style={{ cursor: `pointer`, display: `block` }}
                  onClick={() => handleClickParent(id, taScore)}
                >
                  <div
                    dangerouslySetInnerHTML={{ __html: url.toLowerCase() }}
                  />
                </a>
              </div>
              {!!related.length && (
                <div className="right-block">
                  {related.map((v) => (
                    <div key={v} className="label">
                      {v}
                    </div>
                  ))}
                </div>
              )}
            </AdItem>
          ),
        )
      )}
    </Wrapper>
  )
}

export function capitalizeWords(str) {
  if (!str) return `` // Handle empty or null strings
  return str
    .split(` `) // Split string into words
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) // Capitalize each word
    .join(` `) // Join them back into a single string
}
