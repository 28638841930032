import React, { ReactElement } from 'react'
import { Helmet } from 'react-helmet'

import { Container, Wrapper } from './styles'
import { HomePageProps } from '@/types/pages/home'
import FeaturedArticle from '../../components/article/FeaturedArticle'
import PostArticles from '../../components/article/PostArticles'

/**
 * Home page
 */
export default function Home({ pageContext }: HomePageProps): ReactElement {
  const { aiArticles } = pageContext

  return (
    <Wrapper>
      <Helmet>
        <title>{pageContext.siteSettings.title} - Home</title>
      </Helmet>
      <Container>
        {aiArticles.length > 0 && <FeaturedArticle aiArticle={aiArticles[0]} />}
        {aiArticles.length > 0 && <PostArticles aiArticles={aiArticles} />}
      </Container>
    </Wrapper>
  )
}
